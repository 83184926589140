<template>
  <div class="header" :style="{ background: bgc }">
    <div>
      <i
        class="el-icon-arrow-left"
        @click="return_to"
        v-if="returnBack != '/'"
      ></i>
    </div>
    <div>{{ top_title }}</div>
    <div>
      <img
        v-if="icon_show"
        class="t_icon"
        src="../../assets/img/list_icon.png"
        alt=""
        @click="$router.push('/order_list/order_underway')"
      />
      <span v-else>&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {
      top_title: "",
      icon_show: false,
      return_home: false,
      bgc: "#fff",
      returnBack: this.$route.path,
    };
  },
  watch: {
    "$route.path": function (v) {
      this.returnBack = v;
    },
    $route() {
      setTimeout(() => {
        this.top_title =
          sessionStorage.getItem("hichat_title") || this.$t("main.text1");
        this.icon_show = sessionStorage.getItem("hichat_icon_show") || "";
        this.return_home = sessionStorage.getItem("hichat_return_home") || "";
      }, 0);
      this.top_bgc();
    },
  },
  mounted() {
    //  监听sessiongStorage的值 动态获取数值
    window.addEventListener("setItem", () => {
      this.top_title = sessionStorage.getItem("hichat_title");
    });

    this.top_title =
      sessionStorage.getItem("hichat_title") || this.$t("main.text1");
    this.icon_show = sessionStorage.getItem("hichat_icon_show") || "";
    this.return_home = sessionStorage.getItem("hichat_return_home") || "";
    this.bus.$on("set_title", (res) => {
      this.top_title = res;
    });
    this.top_bgc();
  },
  beforeDestroy() {
    this.bus.$off("set_title");
  },
  methods: {
    return_to() {
      if (this.$route.path == "/") {
        // this.$router.push('/');
      } else {
        if (this.return_home) {
          this.$router.push("/");
        } else {
          this.$router.go(-1);
        }
      }
    },
    top_bgc() {
      if (
        [
          "/buy_fiat_info",
          "/buy_jmhb_info",
          "/buy_appeal",
          "/sell_fiat_info",
          "/sell_jmhb_info",
          "/sell_appeal",
        ].includes(this.$route.path)
      ) {
        this.bgc = "#F5F6F7";
      } else {
        this.bgc = "#fff";
      }
    },
  },
};
</script>

<style scoped>
.header {
  height: 2.75rem;
  line-height: 2.75rem;
  /* background: #fff; */
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  color: #353535;
}
.t_icon {
  width: 1rem;
  vertical-align: sub;
}
</style>
